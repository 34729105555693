@import 'core';

.page-banner #index-banner {
  margin: 5px auto 0;
  display: table;
}
section {
  padding: 30px 0;
  @include media(lg) {
    padding: 100px 0;
  }
  .section-title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 50px;
    @include media(lg) {
      font-size: 28px;
    }
  }
}
section#new-products {
  .showcase {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include media(lg) {
      flex-direction: row;
    }
  }
  .product {
    width: 100%;
    @include media(lg) {
      width: 32.5%;
    }
    &-container {
      display: table;
      margin: 0 auto;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      img {
        border-radius: 5px;
      }
    }
    &-details {
      position: absolute;
      width: 100%;
      top: 43%;
      left: 0;
    }
    &-category {
      color: #fff;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      font-family: Arial, Helvetica, sans-serif;
      @include media(lg) {
        font-size: 24px;
      }
      @include media(tablet) {
        font-size: 32px;
      }
    }
    &-name {
      color: #fff;
      font-size: 22px;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      @include media(lg) {
        font-size: 14px;
      }
      @include media(tablet) {
        font-size: 22px;
      }
    }
    &-category-link {
      display: block;
      font-size: 15px;
      font-weight: 500;
      text-decoration: underline;
      color: #444;

      margin-top: 2px;
      margin-bottom: 15px;
      @include media(lg) {
        margin-top: 10px;
        margin-bottom: 0;
      }
    }
  }
}

/* SECTION Popular series*/
section#popular-series {
  background: #f1f1f1;
  padding-top: 45px;
  @include media(lg) {
    padding-top: 100px;
  }
  .nav-tabs {
    margin-left: 0;
    border-bottom: none;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    border-radius: 5px;
    @include media(tablet) {
      justify-content: center;
    }
    .nav-item {
      border-right: 1px solid #dddddd;
      background: #fff;
      &:first-child {
        border-right: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        .nav-link {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right: 0;
        .nav-link {
          &.active {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
          @include media(lg) {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
          }
        }
      }
    }
    .nav-link {
      //width: 96px;
      text-align: center;
      font-weight: 500;
      font-size: 12px;
      padding: 10px 15px;
      border: none;
      border-radius: unset;
      color: #1a1a1a;
      white-space: nowrap;
      width: 100%;
      @include media(lg) {
        width: 150px;
        padding: 10px;
        font-size: 14px;
      }
      &.active {
        color: #fff;
        background-color: #1a1a1a;

      }
    }
  }
  .tab-content {
    padding-top: 50px;
    @include media(lg) {
      padding-top: 100px;
    }
  }

  .mproducts {
    display: flex;
    overflow: auto;
    @include media(tablet) {
      overflow: visible;;
      justify-content: center;
    }
    .mp-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      min-width: 160px;
      @include media(lg) {
        width: 20%;
      }
      img {
        height: 170px;
        margin-bottom: 15px;
        @include media(lg) {
          height: 200px;
        }
      }
      p {
        color: #333333;
        font-weight: 500;
      }
    }
  }
  .btn-blue {
    display: table;
    margin: 55px auto 0;
    padding: 12px 40px;
  }
}

#popular-series .btn-black {
  display: table;
  margin: 55px auto 0;
  padding: 12px 40px;
}

section#becomepartner {
  padding: 50px 0;
  @include media(lg) {
    padding: 70px 0;
  }
  #partner-text {
    max-width: 100%;
    text-align: center;
    align-self: center;
    @include media(lg) {
      max-width: 90%;
    }
  }
  h3 {
    font-size: 22px;
    font-weight: 500;
    @include media(lg) {
      font-size: 30px;
    }
  }
  p {
    font-size: 17px;
    margin-bottom: 30px;
    @include media(lg) {
      font-size: 20px;
    }
  }
  .btn-blue {
    font-size: 16px;
    padding: 12px 60px;
    @include media(lg) {
      font-size: 20px;
    }
  }
  #partner-img {
    max-width: 157px;
    margin: 0 auto;
    display: table;
    margin-top: 50px;
    @include media(lg) {
      max-width: 330px;
      margin-top: 0;
    }
  }
}


a[href="#"] {
  pointer-events: none;
}