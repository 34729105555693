
    @import '../assets/scss/main.scss';

    #popular-series {
        .mproducts {
            flex-wrap: nowrap !important;
            -ms-overflow-style: none;
            scrollbar-height: none;
            overflow-x: scroll!important;
            /*justify-content: center;*/
            justify-content: unset !important;

            .mp-item {
                min-width: unset !important;
                width: unset !important;

                &:first-child {
                    margin-left: auto;
                }
                &:last-child {
                    margin-right: auto;
                }

                .img-responsive {
                    max-width: unset!important;
                }
            }
        }

        #tabs {
            -ms-overflow-style: none;
            scrollbar-height: none;
            overflow-x: scroll;
            justify-content: unset !important;

            .nav-item {
                &:first-child {
                    margin-left: auto;
                    border-right: 1px solid #dddddd;
                }
                &:last-child {
                    margin-right: auto;
                }
            }
        }

        #tabs::-webkit-scrollbar,
        .mproducts::-webkit-scrollbar {
            display: none;
        }

        a.nav-link {
            min-width: 100px!important;
            width: unset!important;
            cursor: pointer;
        }

        .nav-tabs {
            justify-content: center !important;
        }
    }

    #becomepartner {
        background: #fff;
    }
