
    .left-header {
        display: flex;
        align-items: center;
    }

    .right-menu {
        display: flex;
        align-items: center;
        justify-content: center;
    }
