@import 'mixins/_mixins';

body {
  background: #fff;
  @include media(md) {
    background: #f1f1f1;
  }
}

section {
  padding: 0;
  @include media(lg) {
    padding: 70px 0;
  }
}

#register-form {
  .shadow-form {
    padding: 50px 0 80px;
    border-radius: 5px;
    background: #fff;
    @include media(md) {
      margin: 50px 0;
      padding: 50px 15px 80px;
      box-shadow: 0 10px 20px #0000001C;
    }
    @include media(tablet) {
      padding: 70px 15px;
    }
    @include media(d-md) {
      padding: 70px 120px;
    }
  }

  .form-control-lg {
    height: 55px;
    font-size: 18px;
    border: 1px solid #CCCCCC;
    background: #f8f8f8;
    margin-bottom: 10px;
  }

  .form-title {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    @include media(lg) {
      font-size: 30px;
    }
  }

  .form-description {
    font-size: 17px;
    text-align: center;
    color: #000;
    margin-bottom: 50px;
    @include media(lg) {
      font-size: 20px;
    }
  }

  .form-row>.col {
    padding-right: 15px;
    padding-left: 15px;
  }

  .form-section-title {
    font-size: 20px;
    font-weight: 500;
  }

  .form-section-notitle {
    display: none;
    color: #fff;
    height: 24px;
    @include media(lg) {
      display: block;
    }
  }

  .form-checks {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    .form-check {
      padding-left: 30px;
      font-size: 16px;

      &:first-child {
        margin-bottom: 15px;
      }
      .form-check-input {
        height: 18px;
        width: 18px;
        margin-top: .17rem;
        margin-left: -1.7rem;
        @include media(lg) {
          height: 20px;
          width: 20px;
          margin-top: .15rem;
        }
      }
    }
  }

  .form-button {
    display: flex;
    justify-content: center;
  }

  .btn-blue,
  .btn-orange {
    width: 100%;
    max-width: 340px;
    padding: 15px 40px;
    border-radius: 5px;
    font-weight: 500;
    @include media(lg) {
      .btn-blue {
        padding: 15px 70px;
      }
    }
  }

}

//##### LOGIN PAGE#####
.login-page {
  .shadow-form {
    padding: 70px 70px!important;
    height: 460px;
  }
}
