
.lang-dropdown,
.dropdown-item img {
  width: 34px;
  line-height: 1;
}

.lang-dropdown button {
  line-height: 1;
}

/*.langSwitcher-dropdownMenu {*/
/*min-width: max-content;*/
/*}*/

.d-lg-none .lang-dropdown .dropdown-menu.langSwitcher-dropdownMenu {
  left: unset;
  right: 0;
}

.dropdown-menu.show {
  display: flex;
  flex-wrap: wrap;
  width: max-content;
  max-width: 300px;
  @media (max-width: 384px) {
    font-size: 0.8rem;
    max-width: 235px;
  }
}

.dropdown-menu.show > .dropdown-item {
  flex: 50%;
}
