
section#popular-series .nav-tabs .nav-item {
     border-right: none;
}

section#popular-series .tab-content {
    padding-top: 0;
}

section#popular-series .nav-tabs .nav-item:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

section#popular-series .nav-tabs .nav-item:first-child .nav-link {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

@media (min-width: 768px) {
    section#popular-series .nav-tabs .nav-item:first-child .nav-link {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
    }
}


section#popular-series .nav-tabs .nav-item:last-child {
    border-bottom-left-radius: 5px;
}

@media (min-width: 992px) {
    section#popular-series .mproducts {
        justify-content: left;
        flex-wrap: wrap;
    }
}

@media (min-width: 768px) {
    section#popular-series .mproducts .mp-item img {
        height: 240px;
    }
}

@media (min-width: 768px) {
    section#popular-series .mproducts .mp-item {
        margin-bottom: 50px;
    }
}




